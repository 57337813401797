const apiUrl = process.env.VUE_APP_LARAVEL

const axios = require('axios')

import articles from './api/articles'
import authors from './api/authors'
import books from './api/books'
import bookshelves from './api/bookshelves'
import exports from './api/exports'
import favourites from './api/favourites'
import genres from './api/genres'
import issues from './api/issues'
import magazines from './api/magazines'
import network from './api/network'
import newsletters from './api/newsletters'
import notes from './api/notes'
import quotes from './api/quotes'
import tags from './api/tags'
import topics from './api/topics'
import websites from './api/websites'

/*
const instance = axios.create()
      instance.defaults.withCredentials = true
*/

const apiConfig = {
  namespace: 'api',
  version: 'v1'
}

export default {

  articles,
  authors,
  books,
  bookshelves,
  exports,
  favourites,
  genres,
  issues,
  magazines,
  network,
  newsletters,
  notes,
  quotes,
  tags,
  topics,
  websites,

  client()
  {
    return axios
  },
  
  getUrl(endpoint){
    return `${apiUrl}${apiConfig.namespace}/${apiConfig.version}/${endpoint}/`
  },

  /**
   * Search.
   */
  
  search(s, params={}, advanced=false)
  {
    const endpoint = advanced ? '/advanced/' : '/'

    let url = this.getUrl(`search${endpoint}${s}`)
    
    return this.client().get(url, { params })
  },

  /**
   * Auth.
   */
  
  login(credentials = {})
  {
    let url = this.getUrl('login')

    return this.client().post(url, credentials)
  },

  /**
   * Save a book from ISBN.
   */
  importIsbn(isbn, data={}, config={})
  {
    return this.books.importIsbn(isbn, data, config)
  },

  /**
   * Get a book from ISBN.
   */
  lookupIsbn(isbn)
  {
    return this.books.lookupIsbn(isbn)
  },

  /**
   * Get a book from title.
   */
  lookupTitle(search)
  {
    return this.books.lookupTitle(search)
  },

  /**
   * Search images on Google.
   */
  searchImages(query)
  {
    let url = this.getUrl(`search/images/${query}`)
    
    return this.client().get(url)
  },

  /**
   * Get Booklist statistics.
   */
  stats()
  {
    let url = this.getUrl(`stats`)
    
    return this.client().get(url)
  },

  /**
   * Assemble input models to be passed to the OpenAI service.
   */
  write(items=[])
  {
    let url = this.getUrl(`write`),
        params = { items }
    
    return this.client().post(url, params)
  }

}