<template>
  <div :class="bodyClass">

    <Topbar v-if="isLogged" 
            ref="topbar"
            @toggle="onNavToggle"/>

    <v-content v-touch="{
          right: (e) => onSwipeRight(e)
        }">
      <router-view/>
    </v-content>

    <Loading :status="isLoading"/>

    <Growler/>
    
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

import Growler from './components/Growler';
import Loading from './components/Loading';
import Topbar from './components/Topbar';

export default {
  name: 'Layout',

  components: {
    Growler,
    Loading,
    Topbar,
  },

  computed: {
    ...mapGetters([
      'isLoading',
      'isLogged'
    ])
  },

  data: () => ({
    bodyClass: '',
    navToggleStatus: false
  }),

  methods: {
    logout () {
      this.$store.dispatch('logout')
    },
    onNavToggle(status)
    {
      this.bodyClass = status ? 'nav-toggled' : ''
    },
    onSwipeRight(e)
    {
      /**
       * Hide menu if open.
       */
      const menuSensibility = 100
      
      if( e.offsetX > menuSensibility )
        this.$refs.topbar.showMenu()

      /**
       * Go back in history navigation.
       */
      /*
      const backSensibility = 100

      if( e.touchstartX > showMenuTo && e.offsetX > backSensibility )
        this.$router.go(-1)
      */
    }
  }
};
</script>
