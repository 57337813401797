<template>
  <v-app id="inspire">

    <router-view/>
    
  </v-app>
</template>


<script>

export default {
  name: 'App',

  components: {
    
  },

  computed: {
    
  },

  data: () => ({
    
  }),

  methods: {
    
  }
};
</script>
